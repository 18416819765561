// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [brand, setBrand] = useState('');
  const [canonicalUrl, setCanonicalUrl] = useState('');
  const [preconnectUrl, setPreconnectUrl] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const daftar = urlParams.get('daftar');
    if (!daftar) {
      setError(true);
      return;
    }

    fetch('/zr.txt')
      .then(response => response.text())
      .then(text => {
        const lines = text.split('\n').map(line => line.trim());
        const foundBrand = lines.find(item => item === daftar);
        if (foundBrand) {
          setBrand(foundBrand);
          const canonicalUrl = `https://fh.borobudur.ac.id/wp-includes/js/product?varian=${foundBrand}`;
          setCanonicalUrl(canonicalUrl);
          const preconnectUrl = `https://fh.borobudur.ac.id/wp-includes/js/product?varian=${foundBrand}`;
          setPreconnectUrl(preconnectUrl);
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true));
  }, []);

  if (error) {
    return (
      <div>
        <h1><strong>404 Not Found</strong></h1>
      </div>
    );
  }

  return (
    <main>
      <head>
        {canonicalUrl && (
          <>
            <link rel="preconnect" href={preconnectUrl} />
            <link rel="canonical" href={canonicalUrl} />
          </>
        )}
      </head>
      <div className="atas">
        <div className="atasbox">
          <div>
            <img
              className="imghero"
              height="120"
              width="300"
              alt={brand}
              src="https://lkk.bio/ib/JXoa8Wm8bz.png"
            />
          </div>
          <div className="ataslink">
            <a href="https://ps4d-gacor.xyz/" target="_blank" rel="noopener noreferrer" className="btn1">LOGIN 1</a>
            <a href="https://ps4d-gacor.xyz/" target="_blank" rel="noopener noreferrer">LOGIN 2</a>
            <a href="https://ps4d-gacor.xyz/" target="_blank" rel="noopener noreferrer">RTP Slot</a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
